import React from 'react';
import { Button, Wrapper } from '../../component';
import { ShoppingCartProps } from './types';
import { Helmet } from 'react-helmet';

function ShoppingCart({ productsCart, isAuthenticated }: ShoppingCartProps) {

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  function calculateProfit(value: number | string | undefined): number {
    const numberValue = typeof value === 'string' ? parseFloat(value) : (value ?? 0);
    return numberValue * 1.20;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Tecnoficom | Carrito de Compras</title>
        <meta name="description" content="Revisa y gestiona tus productos en el carrito de compras de Tecnoficom. Compra con comodidad y seguridad." />
        <meta name="keywords" content="Tecnoficom, carrito de compras, productos, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad" />
      </Helmet>
      <div className="grid grid-cols-6 py-12">
        <section className="md:col-span-4 sm:col-span-3 col-span-6">
          <article className="bg-tecnofi-background px-4 py-1">
            <h3 className="font-semibold text-xl text-tecnofi-text text-center">Carrito de Compras</h3>
            <>
              {productsCart && (
                productsCart.map((product, index) => (
                  <article
                    key={`${product.producto_id}-${index}`} // Clave única usando producto_id y el índice
                    className="flex bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100 my-2"
                  >
                    <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background max-w-32 w-full">
                      <img src={product.img_portada} alt={product.titulo} className="h-24" />
                    </figure>
                    <div className="text-xs px-2 py-1 break-words">
                      <h2 className="font-bold line-clamp-3">{product.titulo}</h2>
                      <p className="text-tecnofi-faded-text">{product.modelo}</p>
                      {isAuthenticated && (
                        <p className="font-bold text-tecnofi-primary text-sm">
                          COP: {formatCurrency(calculateProfit(product.precios.precio_descuento))}
                        </p>
                      )}
                      <p className="font-semibold text-tecnofi-faded-text hover:underline">{product.marca}</p>
                      <p>Cantidad: {product.quantity}</p> {/* Mostrar cantidad */}
                    </div>
                  </article>
                ))
              )}
            </>
          </article>
        </section>
        <section className="flex flex-col bg-tecnofi-section rounded-lg md:col-span-2 sm:col-span-3 col-span-6">
          <article className="flex flex-col px-4 py-1 gap-4 my-4">
            <h3 className="text-xl text-tecnofi-text text-center font-semibold">Cotizacion</h3>
            <div className="text-center">
              Costo total
            </div>
            <Button variant="Primary" className="flex justify-center text-[12px] text-center font-medium mx-auto rounded-lg py-2 px-3" type="submit">
              Descargar
            </Button>
          </article>
          <hr className="bg-tecnofi-background text-tecnofi-background rounded-full h-[2px] w-[90%] mx-auto" />
          <article className="flex flex-col px-4 py-1 gap-4 my-4">
            <h3 className="text-xl text-tecnofi-text text-center font-semibold">Factura</h3>
            <div className="text-center">
              Costo total
            </div>
            <Button variant="Primary" className="flex justify-center text-[12px] text-center font-medium mx-auto rounded-lg py-2 px-3" type="submit">
              Descargar
            </Button>
          </article>
        </section>
      </div>
    </Wrapper>
  );
}

export default ShoppingCart;
