import { Navigate, Outlet } from "react-router-dom";
import { ProtectedRoutesProps } from './types';

function ProtectedRoutes({ isAuthenticated, isAdmin, requiredAdmin = false, onLoginPrompt }: ProtectedRoutesProps) {

  if (!isAuthenticated) {
    onLoginPrompt();
    return <Navigate to="/" />;
  }

  if (requiredAdmin && !isAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
