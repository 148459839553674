import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Brand, Home, Categories, Product, ShoppingCart, SearchProduct, Whishlist } from './pages';
import { Header, Footer, Login, Window, ProtectedRoutes, Politics } from './component';
import { Producto } from './pages/Product/component/types';

function App() {
  const [user, setUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [theme, setTheme] = useState(false);
  const [login, setLogin] = useState(false);
  const [politics, setPolitics] = useState(false)
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [cart, setCart] = useState<Producto[]>([]);

  useEffect(() => {
    if (login) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [login]);

  const toggleTheme = () => {
    setTheme(!theme);
  };

  const toggleLogin = (isLogin?: boolean) => {
    setLogin(!login);
    toggleAuthMode(isLogin);
  };

  const toggleAuthMode = (isLogin?: boolean) => {
    setIsLoginMode(isLogin ?? !isLoginMode);
  };

  const togglePolitics = () => {
    setPolitics(!politics)
  }

  const handleLoginPrompt = () => {
    toggleLogin()
  };

  const addToCart = (productToAdd: Producto, quantityToAdd: number = 1) => {
    const existingProductIndex = cart.findIndex(product => product.producto_id === productToAdd.producto_id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += quantityToAdd;
      setCart(updatedCart);
    } else {
      setCart(prevCart => [...prevCart, { ...productToAdd, quantity: quantityToAdd }]);
    }
  };
  
  return (
    <div className={`${theme ? 'dark' : ''} flex flex-col min-h-screen transition-colors`}>
      {login && (
        <Window onClose={() => toggleLogin()}>
          <Login isLoginMode={isLoginMode} toggleAuthMode={toggleAuthMode} togglePolitics={togglePolitics} />
        </Window>
      )}
      {politics && (
        <Window onClose={() => togglePolitics()}>
          <Politics />
        </Window>
      )}
      <Header toggleTheme={toggleTheme} toggleLogin={toggleLogin} valueLogin={login} />
      <main className="flex-1 bg-tecnofi-background transition-colors">
        <Routes>
          <Route index path="/" element={<Home isAuthenticated={user} toggleLogin={toggleLogin} />} />
          <Route path="/brand/:id/:nombre" element={<Brand isAuthenticated={user} toggleLogin={toggleLogin} />} />
          <Route path="/categories/:id/:nombre" element={<Categories isAuthenticated={user} toggleLogin={toggleLogin} />} />
          <Route path="/product/:id/:nombre" element={<Product isAuthenticated={user} addToCart={addToCart} toggleLogin={toggleLogin} />} />
          <Route path="/search/:busqueda" element={<SearchProduct isAuthenticated={user} toggleLogin={toggleLogin} />} />
          <Route element={<ProtectedRoutes isAuthenticated={user} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="/shopping-cart" element={<ShoppingCart productsCart={cart} isAuthenticated={user} />} />
          </Route>
          <Route element={<ProtectedRoutes isAuthenticated={user} isAdmin={isAdmin} requiredAdmin onLoginPrompt={handleLoginPrompt} />}>
            <Route path="/whishlists" element={<Whishlist />} />
          </Route>
        </Routes>
      </main>
      <Footer togglePolitics={togglePolitics} />
    </div>
  );
}

export default App;
