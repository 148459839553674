import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AsideBar, Button, FetchProduct, MenuAsideBar, Wrapper } from '../../../component';
import { Product } from '../../../component/fetch/types';
import { MdOutlineFilterAlt } from "react-icons/md";
import { BrandProps } from '../types';
import { Helmet } from 'react-helmet';

function ProductsMarca({ isAuthenticated, toggleLogin }: BrandProps) {
  const { id } = useParams();
  const [marcaData, setMarcaData] = useState<any>(null);
  const [filters, setFilters] = useState<Product[]>([]);
  const [menuFilter, setMenuFilter] = useState(false);

  const [marcaFilters, setMarcaFilters] = useState<string[]>([]);
  const [categoriaFilters, setCategoriaFilters] = useState<string[]>([]);
  const [minPrecio, setMinPrecio] = useState('');
  const [maxPrecio, setMaxPrecio] = useState('');
  const [descuentoOnly, setDescuentoOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/marcas/${id}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        })
        setMarcaData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        document.body.style.overflow = menuFilter ? 'hidden' : 'auto';
      } else {
        document.body.style.overflow = 'auto';
        setMenuFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [menuFilter]);

  const toggleFilter = () => {
    setMenuFilter(!menuFilter);
  };

  return (
    <>
      <Helmet>
        <title>Tecnoficom | {marcaData ? marcaData.titulo : 'Producto'}</title>
        <meta name="description" content={`Descubre los mejores productos de ${marcaData ? marcaData.titulo : 'nuestra marca aliada'} en Tecnoficom. Expertos en telecomunicaciones y soluciones tecnológicas.`} />
        <meta name="keywords" content={`${marcaData ? marcaData.titulo : 'Marca'}, Tecnoficom, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad`} />
      </Helmet>
      <MenuAsideBar
        className={menuFilter ? 'block' : 'hidden'}
        isOpen={menuFilter}
        toggleFilter={toggleFilter}
        dataFilter={filters}
        marcaFilters={marcaFilters}
        categoriaFilters={categoriaFilters}
        minPrecio={minPrecio}
        maxPrecio={maxPrecio}
        descuentoOnly={descuentoOnly}
        setMarcaFilters={setMarcaFilters}
        setCategoriaFilters={setCategoriaFilters}
        setMinPrecio={setMinPrecio}
        setMaxPrecio={setMaxPrecio}
        setDescuentoOnly={setDescuentoOnly}
        isAuthenticated={isAuthenticated}
      />
      {marcaData && (
        <h2 className="text-tecnofi-text text-center text-3xl font-semibold mt-12 mb-6">{marcaData.titulo}</h2>
      )}
      <Wrapper>
        {filters && filters.length > 0 && (
          <Button variant="Primary" className="rounded-full md:hidden flex my-auto px-[16px] py-[5px]" onClick={toggleFilter}>
            <MdOutlineFilterAlt size={22} fill='#fff' />
          </Button>
        )}
        <div className="grid grid-cols-6">
          <AsideBar
            className={menuFilter ? 'block' : 'hidden'}
            isOpen={menuFilter}
            toggleFilter={toggleFilter}
            dataFilter={filters}
            marcaFilters={marcaFilters}
            categoriaFilters={categoriaFilters}
            minPrecio={minPrecio}
            maxPrecio={maxPrecio}
            descuentoOnly={descuentoOnly}
            setMarcaFilters={setMarcaFilters}
            setCategoriaFilters={setCategoriaFilters}
            setMinPrecio={setMinPrecio}
            setMaxPrecio={setMaxPrecio}
            setDescuentoOnly={setDescuentoOnly}
            isAuthenticated={isAuthenticated}
          />
          <FetchProduct
            isBrand
            idBrand={id}
            setDataFilter={setFilters}
            marcaFilters={marcaFilters}
            categoriaFilters={categoriaFilters}
            minPrecio={minPrecio}
            maxPrecio={maxPrecio}
            descuentoOnly={descuentoOnly}
            isAuthenticated={isAuthenticated}
            toggleLogin={toggleLogin}
          />
        </div>
      </Wrapper>
      {marcaData && marcaData.length > 0 ? (
        <section className="bg-tecnofi-section text-tecnofi-text py-12 mt-[30px]">
          <Wrapper>
            <div className="flex flex-col justify-center items-center gap-6 mb-6">
              <img src={marcaData.logo} alt={marcaData.titulo} className="w-[300px]" />
              <h2 className="text-3xl font-semibold text-center">{marcaData.titulo}</h2>
            </div>
            <p className="text-xs break-words" dangerouslySetInnerHTML={{ __html: marcaData.descripcion }} />
          </Wrapper>
        </section>
      ) : (
        <p className="text-tecnofi-text text-center py-12 mt-[30px]">No sé ha encontrado</p>
      )}
    </>
  );
};

export default ProductsMarca;
