import React from 'react';
import ProductsMarca from './component/ProductsMarca';
import { BrandProps } from './types';

function Brand({ isAuthenticated, toggleLogin }: BrandProps) {
  return (
    <ProductsMarca isAuthenticated={isAuthenticated} toggleLogin={toggleLogin} />
  );
};

export default Brand;