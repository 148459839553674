import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavbarProps, ExchangeRate } from './types';

function TMR({className}: NavbarProps) {
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate>();

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get<ExchangeRate>(`${process.env.REACT_APP_API_URL}/tipocambio`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });
        setExchangeRates(response.data);
      } catch (error: any) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  return (
    <>
      {exchangeRates && (
        <div className={`${className} bg-tecnofi-primary text-[#fff] font-medium rounded-full px-[14px] py-[5px] cursor-pointer hover:underline w-fit`}>
          TRM: {exchangeRates.normal}
        </div>
      )}
    </>
  );
}

export default TMR;
