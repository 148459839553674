import React, { useState, useCallback } from 'react';
import { Wrapper, Window } from '../../component';
import AllLists from './component/AllLists';
import WindowCreateList from './component/WindowCreateList';
import WindowUpdateList from './component/WindowUpdateList';
import { Helmet } from 'react-helmet';

function Wishlists() {
  const [createWindow, setCreateWindow] = useState(false);
  const [updateWindow, setUpdateWindow] = useState(false);
  const [reloadLists, setReloadLists] = useState(false);
  const [selectedListId, setSelectedListId] = useState<number | null>(null);

  const toggleCreateWindow = () => {
    setCreateWindow(!createWindow);
  };

  const toggleUpdateWindow = () => {
    setUpdateWindow(!updateWindow);
  };

  const handleAddNewList = useCallback(async () => {
    try {
      setCreateWindow(false);
      setReloadLists(prev => !prev);
    } catch (err) {
      console.error('Error adding new list:', err);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Tecnoficom | Listas de Deseos</title>
        <meta name="description" content="Explora y administra tus listas de deseos en Tecnoficom. Crea nuevas listas y actualiza las existentes según tus necesidades." />
        <meta name="keywords" content="Tecnoficom, listas de deseos, categorías, administrar listas, productos, personalizar, organizar, preferencias, guardar productos" />
      </Helmet>
      <Wrapper isContent>
        <AllLists
          toggleCreateWindow={toggleCreateWindow}
          toggleUpdateWindow={toggleUpdateWindow}
          reload={reloadLists}
          setSelectedListId={setSelectedListId}
        />
      </Wrapper>
      <Window className={`${createWindow ? '' : 'hidden'}`} onClose={toggleCreateWindow}>
        <WindowCreateList addNewList={handleAddNewList} />
      </Window>
      <Window className={`${updateWindow ? '' : 'hidden'}`} onClose={toggleUpdateWindow}>
        {selectedListId !== null && (
          <WindowUpdateList id_lista={selectedListId} />
        )}
      </Window>
    </>
  );
}

export default Wishlists;
