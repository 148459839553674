import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoNavbar from './component/LogoNavbar';
import SearchBar from './component/Searchbar';
import Wrapper from '../wrapper/Wrapper';
import Menu from './component/Menu';
import Navbar from './component/Navbar';
import Button from '../button/Button';
import TMR from './component/TMR';
import { TiThMenu } from 'react-icons/ti';
import { MdShoppingCart, MdOutlineDarkMode, MdOutlineLightMode, MdOutlineAccountCircle } from "react-icons/md";
import { HeaderProps } from './types';

function Header({ toggleTheme, toggleLogin }: HeaderProps) {
  const [menu, setMenu] = useState(false);
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        document.body.style.overflow = menu ? 'hidden' : 'auto';
      } else {
        document.body.style.overflow = 'auto';
        setMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [menu]);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const toggleThemeHandler = () => {
    setTheme(!theme);
    toggleTheme();
  };

  const handleToggleLogin = () => {
    toggleLogin();
  };

  return (
    <header className="bg-tecnofi-background lg:border-[0px] border-b-[3px] border-tecnofi-primary">
      <Menu isOpen={menu} toggleMenu={toggleMenu} className={menu ? 'block' : 'hidden'} />
      <Wrapper>
        <div className="flex justify-between items-center gap-[8px] py-[12px]">
          <LogoNavbar />
          <div className="flex items-center gap-1">
            <Button variant="Primary" className="flex my-auto rounded-full px-[15px] py-[4px]" onClick={handleToggleLogin}>
              <MdOutlineAccountCircle size={24} fill="#FFF" />
            </Button>
            <Button variant="Primary" className="flex my-auto rounded-full px-[16px] py-[5px]" onClick={toggleThemeHandler}>
              {theme ? <MdOutlineLightMode size={22} fill="#FFF" /> : <MdOutlineDarkMode size={22} fill="#FFF" />}
            </Button>
            <Link to={"/shopping-cart"} className="flex my-auto bg-tecnofi-primary font-medium rounded-full px-[16px] py-[5px] cursor-pointer hover:underline w-fit">
              <MdShoppingCart size={22} fill="#FFF" />
            </Link>
            <Button variant="Primary" className="flex my-auto lg:hidden rounded-full px-[16px] py-[5px]" onClick={toggleMenu}>
              <TiThMenu size={22} fill="#FFF" />
            </Button>
            <TMR className="lg:flex hidden" />
          </div>
        </div>
      </Wrapper>
      <Wrapper isContent>
        <SearchBar className="hidden lg:block" toggleMenu={toggleMenu} />
      </Wrapper>
      <Navbar className="lg:block hidden" />
    </header>
  );
}

export default Header;
