import React from 'react';
import Banner from './component/Banner';
import { Wrapper } from '../../component';
import Brands from './component/Brands';
import { images } from './data/banner';
import brand from './data/brand.json';
import categories from './data/categories.json';
import Categories from './component/Categories';
import ProductosDestacados from './component/ProductosDestacados';
import Access from './component/Access';
import { Helmet } from 'react-helmet';

interface HomeProps {
  toggleLogin: (isLogin?: boolean) => void;
  isAuthenticated: boolean;
}

function Home({ toggleLogin, isAuthenticated }: HomeProps) {
  return (
    <div className="flex flex-col justify-center">
      <Helmet>
        <title>Tecnoficom | Home</title>
        <meta name="description" content="Empresa líder en telecomunicaciones, enfocada en la excelencia y el servicio al cliente a nivel nacional." />
        <meta name="keywords" content="Tecnoficom, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad"/>
      </Helmet>
      <section className="max-w-full w-full">
        <Banner images={images} toggleLogin={toggleLogin} />
      </section>
      <Wrapper>
        <section className="max-w-full w-full">
          <Brands brand={brand} />
        </section>
      </Wrapper>
      <section className="bg-tecnofi-section">
        <Wrapper>
          <Categories categories={categories} />
        </Wrapper>
      </section>
      <ProductosDestacados isAuthenticated={isAuthenticated} toggleLogin={toggleLogin} />
      <Access toggleLogin={toggleLogin} />
    </div>
  );
}

export default Home;
