import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '../../../component';
import { FaSearch } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Product, ProductSelectProps } from './types';

function ProductSelect({ onProductClick }: ProductSelectProps) {
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [busqueda, setBusqueda] = useState('');
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (busqueda.trim() !== '') {
      fetchData(pagina);
    } else {
      setSearchResults([]);
      setTotalPaginas(1);
    }
  }, [pagina, busqueda]);

  const fetchData = async (page: number) => {
    try {
      setHidden(true);
      const params: { [key: string]: string | number } = { pagina: page };
      if (busqueda.trim() !== '') params.busqueda = busqueda.split(' ').join('+');

      const productosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/productos`, {
        params,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });

      if (productosResponse.data && Array.isArray(productosResponse.data.productos) && productosResponse.data.productos.length > 0) {
        const productos: Product[] = productosResponse.data.productos;
        setSearchResults(productos);
        setTotalPaginas(productosResponse.data.paginas);
      } else {
        setSearchResults([]);
        setTotalPaginas(1);
      }
      setTimeout(() => setHidden(false), 1000);
    } catch (err) {
      console.error('Error fetching search results:', err);
      setHidden(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBusqueda(e.target.value);
  };

  const handleSearch = () => {
    setPagina(1);
    fetchData(1);
  };

  const handleNextPage = () => {
    setPagina((prevPagina) => Math.min(prevPagina + 1, totalPaginas));
  };

  const handlePreviousPage = () => {
    setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  return (
    <div className="w-full h-full">
      <section className="flex justify-between items-center border-[3px] border-tecnofi-primary relative bg-tecnofi-background w-full rounded-full px-[4px] py-[2px] my-4 gap-[2px]">
        <input
          type="text"
          value={busqueda}
          onChange={handleInputChange}
          className="bg-tecnofi-background text-tecnofi-text w-full rounded-full focus:outline-none font-medium font-poppins placeholder-text-tecnofi-faded-text px-[8px] py-[4px]"
          placeholder="Buscar"
        />
        <Button type="button" variant="Primary" className="rounded-full px-[16px] py-[7px]" onClick={handleSearch}>
          <FaSearch fill="#fff" />
        </Button>
      </section>
      <section className="flex justify-between items-center font-medium mt-4">
        <Button type="button" variant="Primary" className="rounded-full p-[8px]" onClick={handlePreviousPage} disabled={pagina === 1}>
          <FaAngleLeft size="22" fill="#fff" />
        </Button>
        <span className="text-tecnofi-text text-center font-medium">
          Página {pagina} de {totalPaginas}
        </span>
        <Button type="button" variant="Primary" className="rounded-full p-[8px]" onClick={handleNextPage} disabled={pagina === totalPaginas}>
          <FaAngleRight size="22" fill="#fff" />
        </Button>
      </section>
      <section className={`grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4 ${hidden ? 'hidden' : ''}`}>
        {searchResults.map((producto: Product) => (
          <article
            key={producto.producto_id}
            className="bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100 cursor-pointer"
            onClick={() => onProductClick(producto.producto_id)}
          >
            <span className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
              <img src={producto.img_portada} alt={producto.titulo} className="h-24" />
            </span>
            <div className="text-xs px-2 py-1 break-words">
              <h2 className="font-bold line-clamp-3">{producto.titulo}</h2>
              <p className="text-tecnofi-faded-text">{producto.modelo}</p>
              <p className="font-semibold text-tecnofi-primary hover:underline">{producto.marca}</p>
            </div>
          </article>
        ))}
      </section>
    </div>
  );
}

export default ProductSelect;
