import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '../../../component';
import { TiDocumentAdd, TiDocumentDelete } from "react-icons/ti";
import { Wishlist, AllListsProps } from './types';

function AllLists({ toggleCreateWindow, toggleUpdateWindow, reload, setSelectedListId }: AllListsProps) {
  const [lists, setLists] = useState<Wishlist[]>([]);

  const fetchData = async () => {
    try {
      const listsResponse = await axios.get<Wishlist[]>(`${process.env.REACT_APP_API_URL}/wishlists`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      }); 
      setLists(listsResponse.data);
    } catch (err) {
      console.error('Error fetching wishlist:', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reload]);

  const deleteList = async (id: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/wishlists/${id}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });
      setLists(prevLists => prevLists.filter(list => list.id !== id));
    } catch (error) {
      console.error('Error deleting wishlist:', error);
    }
  };

  const handleListClick = (id: string) => {
    toggleUpdateWindow();
    setSelectedListId(+id);
  };

  return (
    <>
      <section className="flex flex-col justify-center mt-12 mb-6 gap-1">
        <h2 className="text-tecnofi-text text-center text-3xl font-semibold my-auto break-words">Listas</h2>
        <article className="flex justify-between">
          <h3 className="text-tecnofi-text text-sm font-semibold mt-auto">Listas actuales</h3>
          <Button variant="Primary" className="flex rounded-full my-auto px-[16px] py-[5px]" onClick={toggleCreateWindow}>
            <TiDocumentAdd size={22} fill="#FFF" />
          </Button>
        </article>
      </section>
      <section className="flex flex-col 2 my-4">
        {lists.map((list: Wishlist) => (
          <article key={list.id} className="flex justify-between items-center cursor-pointer bg-tecnofi-section w-full rounded-[8px] text-sm text-tecnofi-text hover:drop-shadow-lg my-1 px-8 py-4" onClick={() => handleListClick(list.id)}>
            <h4 className="font-semibold">{list.nombre}</h4>
            <Button variant="Primary" className="flex rounded-full my-auto px-[16px] py-[5px]" onClick={() => deleteList(list.id)}>
              <TiDocumentDelete size="22" fill="#fff" />
            </Button>
          </article>
        ))}
      </section>
    </>
  );
}

export default AllLists;
