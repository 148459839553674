import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUpEmpresa({ togglePolitics }: { togglePolitics: () => void }) {
  const [nombre, setNombre] = useState('');
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [emailFac, setEmailFac] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [nit, setNIT] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const toggleSelectedClient = (client: string) => {
    setSelectedClient(client);
    setToggleSelected(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!nombre || !nombreEmpresa || !telefono || !email || !emailFac || !selectedClient || !nit) {
      toast.error('Por favor, completa todos los campos.');
      return;
    }

    const templateParams = {
      nombre,
      nombreEmpresa,
      telefono,
      email,
      emailFac,
      nit,
      selectedClient,
    };

    emailjs.send('service_1w8fnax', 'template_s43hvco', templateParams, 'rnEjS-QFisajygUAA')
      .then((response) => {
        toast.success('Formulario enviado exitosamente.');
        setNombre('');
        setNombreEmpresa('');
        setTelefono('');
        setEmail('');
        setEmailFac('');
        setNIT('');
        setSelectedClient(null);
      }, (err) => {
        console.log('FAILED...', err);
        toast.error('Error al enviar el formulario. Inténtalo de nuevo.');
      });
  };

  const handdlePolitics = () => {
    togglePolitics();
  }

  return (
    <section className="flex flex-col items-center w-full text-tecnofi-text">
      <ToastContainer />
      <form className="w-full" onSubmit={handleSubmit}>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-4">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="nombre">
              Nombre Completo
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="nombre"
              type="text"
              placeholder="Nombre Completo"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="email">
              Correo de Notificaciones
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="email"
              type="email"
              placeholder="Correo de Notificaciones"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="telefono">
              Teléfono de Contacto
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="telefono"
              type="tel"
              placeholder="Teléfono de Contacto"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="nombreEmpresa">
              Razón Social
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="nombreEmpresa"
              type="text"
              placeholder="Razón Social"
              value={nombreEmpresa}
              onChange={(e) => setNombreEmpresa(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="NIT">
              NIT de la Empresa
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="NIT"
              type="number"
              placeholder="NIT de la empresa"
              value={nit}
              onChange={(e) => setNIT(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="emailFac">
              Correo de Facturación
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="emailFac"
              type="email"
              placeholder="Correo de Facturación"
              value={emailFac}
              onChange={(e) => setEmailFac(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-2 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="file">
              Adjuntar archivo
            </label>
            <input
              className="hidden"
              id="file"
              type="file"
              onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
            />
            <label
              htmlFor="file"
              className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer w-[full] px-4 h-[52px] bg-tecnofi-section">
              Sube tu RUT
            </label>
          </article>
          <div className="relative flex flex-col">
            <label className="flex text-base font-medium mb-2">
              Seleccionar el Tipo de Cliente
            </label>
            <label
              className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
              onClick={() => setToggleSelected(!toggleSelected)}
            >
              {selectedClient ? selectedClient : 'Seleccionar Cliente'}
            </label>
            {toggleSelected && (
              <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md">
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleSelectedClient('Cliente final')}>
                  Cliente final
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleSelectedClient('Distribuidor de Tecnología')}>
                  Distribuidor de Tecnología
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleSelectedClient('Integrador de soluciones')}>
                  Integrador de soluciones
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleSelectedClient('Gobierno')}>
                  Gobierno
                </div>
              </div>
            )}
          </div>
        </section>
        <p className="text-sm text-center mb-4">
          Al registrarse, usted acepta nuestras <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Políticas de Privacidad</span>, <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Términos y Condiciones</span> y el uso del correo electrónico como medio de notificaciones de novedades.
        </p>
        <Button
          variant="Primary"
          className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3 mt-4"
          type="submit"
        >
          Regístrese
        </Button>
      </form>
    </section>
  );
}

export default SignUpEmpresa;
