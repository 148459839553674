import React from 'react';
import ProductData from './component/ProductData';
import ProductRelated from './component/ProductRelated';
import { ProductProps } from './types';

function Product({ isAuthenticated, addToCart, toggleLogin }: ProductProps) {
  return (
    <>
      <ProductData isAuthenticated={isAuthenticated} addToCart={addToCart} toggleLogin={toggleLogin} />
      <ProductRelated isAuthenticated={isAuthenticated} />
    </>
  );
};

export default Product;