export const images = [
    '/assets/banner/background-ofice.jpg',
    '/assets/banner/banner-one.webp',
    '/assets/banner/banner-two.jpg',
    '/assets/banner/banner-three.webp',
    '/assets/banner/banner-four.jpg',
    '/assets/banner/banner-five.jpg',
    '/assets/banner/banner-six.jpg',
    '/assets/banner/banner-seven.jpg',
    '/assets/banner/banner-eight.png',
  ];