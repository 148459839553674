import React from 'react';
import { Button, Wrapper } from '../../../component';
import { AccessProps } from './types';


function Access({ toggleLogin }: AccessProps) {

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    } else {
      console.error('toogleLogin function is not defined.');
    }
  }

  return (
    <section className="bg-tecnofi-primary text-tecnofi-background py-10">
      <Wrapper>
        <div className="grid md:grid-cols-2">
          <img
            src="https://www.tecnoficom.com/wp-content/uploads/2024/05/Tecnoficom-LTDA.jpg"
            alt="Inicia Sesión"
            className="hidden md:flex text-center rounded-[32px] h-[400px] mx-auto my-auto"
          />
          <article className="flex flex-col justify-center items-center">
            <h4 className="font-semibold text-4xl text-center mb-4">
              Registre su Empresa y Acceda a Precios y Promociones Exclusivas
            </h4>
            <p>
              En Tecnoficom LTDA puede Adquirir Todo en Equipos de Oficina, Tecnología y Comunicaciones, Acceda al Portafolio Completo con mas de 15.000 productos a su Disposición!.
            </p>
            <Button
              onClick={handleLogin}
              className="flex bg-tecnofi-secondary text-[#fff] font-medium rounded-full text-xl px-5 py-3 mt-5 mx-auto"
            >
              Accede
            </Button>
          </article>
        </div>
      </Wrapper>
    </section>
  );
}

export default Access;