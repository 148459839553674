import React from 'react';
import ProductsFetch from './component/ProductSubCategories';
import { CategoriesProps } from './types';

function Categories({ isAuthenticated, toggleLogin }: CategoriesProps) {
  return (
    <ProductsFetch isAuthenticated={isAuthenticated} toggleLogin={toggleLogin} />
  );
};

export default Categories;