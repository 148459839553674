import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { FetchProductProps, Product, Paginas } from './types';
import Button from '../button/Button';

function FetchProduct(
  {
    idBrand,
    idCategory,
    searchParams,
    isBrand = false,
    isCategory = false,
    isSearch = false,
    setDataFilter,
    marcaFilters,
    categoriaFilters,
    minPrecio,
    maxPrecio,
    descuentoOnly,
    isAuthenticated,
    toggleLogin
  }: FetchProductProps) {
  const [productos, setProductos] = useState<Product[]>([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);

  useEffect(() => {
    setPagina(1);
    setTotalPaginas(1);
    setDataFilter([])
  }, [isBrand, isCategory, isSearch, idBrand, idCategory, searchParams]);

  useEffect(() => {
    setProductos([]);
    if (isBrand) {
      fetchBrand(pagina);
    } else if (isCategory) {
      fetchCategory(pagina);
    } else if (isSearch) {
      fetchSearch(pagina);
    }
  }, [isBrand, isCategory, isSearch, idBrand, idCategory, searchParams, pagina]);

  const fetchBrand = async (page: number) => {
    try {
      const response = await axios.get<Paginas>(`${process.env.REACT_APP_API_URL}/productos`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        },
        params: { marca: idBrand, pagina: page, cop: true },
      });
      if (response.data && response.data.productos && response.data.productos.length > 0) {
        setProductos(response.data.productos);
        setTotalPaginas(response.data.paginas);
        setDataFilter(response.data.productos);
      } else {
        console.error('Error fetching products: Response data or productos array is missing or empty');
      }
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchCategory = async (page: number) => {
    try {
      const response = await axios.get<Paginas>(`${process.env.REACT_APP_API_URL}/productos`, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
        params: { pagina: page, categoria: idCategory, cop: true },
      });
      if (response.data && response.data.productos && response.data.productos.length > 0) {
        setProductos(response.data.productos);
        setTotalPaginas(response.data.paginas);
        setDataFilter(response.data.productos);
      } else {
        console.error('Error fetching products: Response data or productos array is missing or empty');
      }
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchSearch = async (page: number) => {
    try {
      const params: { [key: string]: string | number } = { pagina: page, cop: 1 };
      if (searchParams) params.busqueda = searchParams.split(' ').join('+');
      const response = await axios.get<Paginas>(`${process.env.REACT_APP_API_URL}/productos`, {
        params,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });
      if (response.data && response.data.productos && response.data.productos.length > 0) {
        setProductos(response.data.productos);
        setTotalPaginas(response.data.paginas);
        setDataFilter(response.data.productos);
      } else {
        console.error('Error fetching products: Response data or productos array is missing or empty');
      }
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  };

  const handleNextPage = () => {
    setPagina((prevPagina) => Math.min(prevPagina + 1, totalPaginas));
  };

  const handlePreviousPage = () => {
    setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    } else {
      console.error('toggleLogin function is not defined.');
    }
  }

  const filterProducts = (): Product[] => {
    return productos.filter((producto) => {
      let marcaFiltered = false;
      let categoriaFiltered = false;

      if (
        marcaFilters?.length === 0 ||
        marcaFilters?.some((marca) => producto.marca.toLowerCase() === marca.toLowerCase())
      ) {
        marcaFiltered = true;
      }

      if (
        categoriaFilters?.length === 0 ||
        producto.categorias.some((cat) => {
          if (typeof cat === 'string') {
            return categoriaFilters?.includes(cat);
          } else {
            return categoriaFilters?.includes(cat.nombre);
          }
        })
      ) {
        categoriaFiltered = true;
      }

      const precio = parseFloat(producto.precios.precio_lista);
      const precioInRange =
        (!minPrecio || precio >= parseFloat(minPrecio)) &&
        (!maxPrecio || precio <= parseFloat(maxPrecio));

      const descuentoValido = !descuentoOnly || !!producto.precios.precio_descuento;
      return marcaFiltered && categoriaFiltered && precioInRange && descuentoValido;
    });
  };

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  function calculateProfit(value: number | string | undefined): number {
    const numberValue = typeof value === 'string' ? parseFloat(value) : (value ?? 0);
    return numberValue * 1.20;
  }

  return (
    <div className="md:col-span-4 col-span-6 w-full h-full">
      {totalPaginas <= 1 ? null : (
        <section className="flex justify-between items-center font-medium mt-4">
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handlePreviousPage}
            disabled={pagina === 1}
          >
            <FaAngleLeft size="22" fill="#fff" />
          </Button>
          <span className="text-tecnofi-text text-center font-medium">
            Página {pagina} de {totalPaginas}
          </span>
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handleNextPage}
            disabled={pagina === totalPaginas}
          >
            <FaAngleRight size="22" fill="#fff" />
          </Button>
        </section>
      )}
      <section className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4">
        {filterProducts().map((producto: Product) => (
          <article
            key={producto.producto_id}
            className="flex flex-col justify-between bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
          >
            <Link to={`/product/${producto.producto_id}/${encodeURIComponent(producto.titulo)}`}>
              <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                <img src={producto.img_portada} alt={producto.titulo} className="h-24" />
              </figure>
              <div className="text-xs px-2 py-1 break-words">
                <h2 className="font-bold line-clamp-3">{producto.titulo}</h2>
                <p className="text-tecnofi-faded-text">{producto.modelo}</p>
                {isAuthenticated && (
                  <p className="font-bold text-tecnofi-primary text-sm">
                    COP: {formatCurrency(calculateProfit(producto.precios.precio_descuento))}
                  </p>
                )}
                <p className="font-semibold text-tecnofi-faded-text hover:underline">{producto.marca}</p>
              </div>
            </Link>
            {!isAuthenticated && (
              <Button
                variant="Primary"
                className="w-full flex justify-center text-xs text-center rounded font-medium mx-auto mt-2 py-2 px-3"
                onClick={handleLogin}
              >
                Acceder y Ver Precio
              </Button>
            )}
          </article>
        ))}
      </section>
    </div>
  );
}

export default FetchProduct;