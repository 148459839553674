import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../button/Button';
import Filter from './Filter';
import { FaSearch } from 'react-icons/fa';
import { TbBrandSafari } from 'react-icons/tb';
import { NavbarProps } from './types';

function SearchBar({ className, onClose, toggleMenu }: NavbarProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(`/search/${encodeURIComponent(searchTerm)}`);
    setSearchTerm('');
    if (toggleMenu) {
      toggleMenu();
    } else if (onClose) {
      onClose();
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className={`py-[10px] ${className}`}>
      <form onSubmit={handleSearch} className="flex justify-between items-center border-[3px] border-tecnofi-primary relative bg-tecnofi-background w-full rounded-full px-[4px] py-[2px] gap-[2px]">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="bg-tecnofi-background text-tecnofi-text w-full rounded-full focus:outline-none font-medium font-poppins placeholder-text-tecnofi-faded-text px-[8px] py-[4px]"
          placeholder="Search"
        />
        <Button type="submit" variant="Primary" className="rounded-full px-[16px] py-[7px]">
          <FaSearch fill="#fff" />
        </Button>
        <Button type="button" variant="Primary" className="flex rounded-full px-[13px] py-[4px]" onClick={toggleFilter}>
          <TbBrandSafari size={22} color="white" />
        </Button>
        {showFilter && <Filter onClose={toggleFilter} toggleMenu={toggleMenu || (() => {})} />}
      </form>
    </div>
  );
}

export default SearchBar;
