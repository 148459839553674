import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { AsideBarProps, Product, Category } from './types';
import Button from '../button/Button';
import { IoClose } from 'react-icons/io5';
import LogoNavbar from '../header/component/LogoNavbar';
import TMR from '../header/component/TMR';

function MenuAsideBar({
  className,
  toggleFilter,
  isOpen,
  dataFilter,
  marcaFilters,
  categoriaFilters,
  minPrecio,
  maxPrecio,
  descuentoOnly,
  setMarcaFilters,
  setCategoriaFilters,
  setMinPrecio,
  setMaxPrecio,
  setDescuentoOnly,
  isAuthenticated
}: AsideBarProps) {
  const asideRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      gsap.to(asideRef.current, { y: 0, duration: 0.5, ease: 'power3.out' });
      document.body.classList.add('overflow-hidden');
    } else {
      gsap.to(asideRef.current, { y: '-100%', duration: 0.5, ease: 'power3.in' });
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const handleToggleMenu = () => {
    if (toggleFilter) {
      toggleFilter();
    }
  };

  let marcas: string[] = [];
  let categorias: string[] = [];

  dataFilter.forEach((product: Product) => {
    if (product.marca && !marcas.includes(product.marca)) {
      marcas.push(product.marca);
    }
    if (product.categorias && product.categorias.length > 0) {
      product.categorias.forEach((categoria: Category | string) => {
        if (typeof categoria === 'string') {
          if (!categorias.includes(categoria)) {
            categorias.push(categoria);
          }
        } else {
          if (!categorias.includes(categoria.nombre)) {
            categorias.push(categoria.nombre);
          }
        }
      });
    }
  });

  const handleMarcaChange = (marca: string) => {
    if (marcaFilters.includes(marca)) {
      setMarcaFilters(marcaFilters.filter((m) => m !== marca));
    } else {
      setMarcaFilters([...marcaFilters, marca]);
    }
  };

  const handleCategoriaChange = (categoria: string | Category) => {
    if (typeof categoria === 'string') {
      if (categoriaFilters.includes(categoria)) {
        setCategoriaFilters(categoriaFilters.filter((c) => c !== categoria));
      } else {
        setCategoriaFilters([...categoriaFilters, categoria]);
      }
    } else {
      if (categoriaFilters.includes(categoria.nombre)) {
        setCategoriaFilters(categoriaFilters.filter((c) => c !== categoria.nombre));
      } else {
        setCategoriaFilters([...categoriaFilters, categoria.nombre]);
      }
    }
  };

  const handleMinPrecioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrecio(event.target.value);
  };

  const handleMaxPrecioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrecio(event.target.value);
  };

  const handleDescuentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescuentoOnly(event.target.checked);
  };

  return (
    <div ref={asideRef} className={`${className} bg-tecnofi-background text-tecnofi-text fixed z-40 w-screen h-full left-0 top-0 px-8 pt-10 pb-[32px]`}>
      <Button variant="Primary" className="p-[8px] rounded-bl-[8px] absolute right-0 top-0" onClick={handleToggleMenu}>
        <IoClose size={22} fill="#fff" />
      </Button>
      <div className="flex justify-between items-center pb-3">
        <LogoNavbar />
        <TMR />
      </div>
      {dataFilter.length > 0 && (
        <section className="flex flex-col h-full overflow-y-auto pb-10">
          <article>
            <div className="mb-4">
              <h4 className="font-semibold text-sm text-center mb-2">Marcas</h4>
              {marcas.map((marca) => (
                <div key={marca} className="flex items-center my-1">
                  <input
                    type="checkbox"
                    id={marca}
                    checked={marcaFilters.includes(marca)}
                    onChange={() => handleMarcaChange(marca)}
                    className="hidden"
                  />
                  <label
                    htmlFor={marca}
                    className={`bg-tecnofi-primary font-medium text-[#fff] rounded-[4px] cursor-pointer select-none px-[12px] py-[6px] ${marcaFilters.includes(marca) ? 'bg-tecnofi-secondary' : ''}`}
                  >
                    {marca}
                  </label>
                </div>
              ))}
            </div>
          </article>
          <article>
            <div className="mb-4">
              <h4 className="font-semibold text-sm text-center mb-2">Categorías</h4>
              {categorias.map((categoria) => (
                <div key={categoria} className="flex items-center my-1">
                  <input
                    type="checkbox"
                    id={categoria}
                    checked={categoriaFilters.includes(categoria)}
                    onChange={() => handleCategoriaChange(categoria)}
                    className="hidden"
                  />
                  <label
                    htmlFor={categoria}
                    className={`bg-tecnofi-primary font-medium text-[#fff] rounded-[4px] cursor-pointer select-none px-[12px] py-[6px] ${categoriaFilters.includes(categoria) ? 'bg-tecnofi-secondary' : ''}`}
                  >
                    {categoria}
                  </label>
                </div>
              ))}
            </div>
          </article>
          {isAuthenticated && (
            <article className="mb-4">
              <h4 className="font-semibold text-sm text-center">Precio</h4>
              <div className="flex flex-col items-start">
                <label htmlFor="minPrecio" className="mr-2">Min:</label>
                <input
                  type="text"
                  id="minPrecio"
                  value={minPrecio}
                  onChange={handleMinPrecioChange}
                  className="border-[2px] border-tecnofi-primary rounded-[4px] w-full py-1 px-2 bg-tecnofi-section focus:outline-none"
                />
                <label htmlFor="maxPrecio" className="mr-2">Max:</label>
                <input
                  type="text"
                  id="maxPrecio"
                  value={maxPrecio}
                  onChange={handleMaxPrecioChange}
                  className="border-[2px] border-tecnofi-primary rounded-[4px] w-full py-1 px-2 bg-tecnofi-section focus:outline-none"
                />
                <input
                  type="checkbox"
                  id="descuentoOnly"
                  checked={descuentoOnly}
                  onChange={handleDescuentoChange}
                  className="hidden"
                />
                <label
                  htmlFor="descuentoOnly"
                  className={`bg-tecnofi-primary font-medium text-[#fff] rounded-[4px] cursor-pointer select-none px-[12px] py-[6px] mt-2 ${descuentoOnly ? 'bg-tecnofi-secondary' : ''}`}
                >
                  En oferta
                </label>
              </div>
            </article>
          )}
        </section>
      )}
    </div>
  );
}

export default MenuAsideBar;
