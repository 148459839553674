import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Marca, FilterProps } from './types';
import axios from 'axios';

function Filter({ toggleMenu }: FilterProps) {
  const [brands, setBrands] = useState<Marca[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Marca[]>(`${process.env.REACT_APP_API_URL}/marcas`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });
        setBrands(response.data);
      } catch (error: any) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleLinkClick = () => {
    toggleMenu && toggleMenu();
  };

  return (
    <div className="absolute rounded-[8px] z-50 border border-tecnofi-section bg-tecnofi-background lg:max-w-[500px] max-h-[550px] max-w-[250px] p-[20px] right-0 top-[40px] overflow-auto">
      {brands.map((brand) => (
        <Link key={brand.id} to={`/brand/${brand.id}/${encodeURIComponent(brand.nombre)}`} className="block text-tecnofi-text" onClick={handleLinkClick}>
          {brand.nombre}
        </Link>
      ))}
    </div>
  );
}

export default Filter;
