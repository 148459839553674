import React, { useEffect } from 'react';

function Politics() {

  useEffect(() => {
    const addClassToHeadings = () => {
      const headings = document.querySelectorAll('h1, h2, h3');
      headings.forEach(heading => {
        heading.classList.add('text-tecnofi-primary');
      });
    };
    addClassToHeadings();
  }, []);

	return (
		<div className="text-tecnofi-text mx-auto p-4 overflow-y-auto">
			<h1 className="text-2xl text-center font-bold mb-4">Política de Privacidad de Tienda Tecnoficom</h1>
			<p className="mb-2">Bienvenido a Tienda Tecnoficom. En Tecnoficom, respetamos y protegemos la privacidad de nuestros usuarios y nos comprometemos a ser transparentes sobre cómo recopilamos, utilizamos y compartimos su información. Esta política de privacidad describe nuestras prácticas en relación con la información recopilada a través de nuestro sitio web (www.tecnoficom.com).</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">1. Información que Recopilamos</h2>
			<h3 className="font-semibold">Información Personal</h3>
			<p className="mb-2">Podemos recopilar la siguiente información personal a través de formularios de registro y otros métodos:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Nombre completo</li>
				<li>Dirección de correo electrónico</li>
				<li>Número de teléfono</li>
				<li>Dirección física</li>
				<li>Información de pago (si aplica)</li>
			</ul>

			<h3 className="font-semibold">Información No Personal</h3>
			<p className="mb-2">Podemos recopilar información no personal mediante cookies y tecnologías de seguimiento, como:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Dirección IP</li>
				<li>Tipo de navegador</li>
				<li>Sistema operativo</li>
				<li>Páginas visitadas y duración de la visita</li>
				<li>Enlaces clicados</li>
			</ul>

			<h2 className="text-xl font-semibold mt-4 mb-2">2. Métodos de Recopilación de Datos</h2>
			<h3 className="font-semibold">Formularios de Registro</h3>
			<p className="mb-2">Recopilamos información personal cuando los usuarios se registran en nuestro sitio web, realizan compras o se suscriben a nuestros boletines informativos.</p>
			<h3 className="font-semibold">Cookies y Tecnologías de Seguimiento</h3>
			<p className="mb-2">Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, analizar el tráfico del sitio y personalizar el contenido y la publicidad. Puede gestionar sus preferencias de cookies a través de la configuración de su navegador.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">3. Uso de la Información Recopilada</h2>
			<p className="mb-2">Utilizamos la información recopilada para los siguientes propósitos:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Marketing: Enviar boletines informativos, promociones y actualizaciones sobre productos y servicios.</li>
				<li>Servicio al Cliente: Responder a consultas, proporcionar asistencia y gestionar pedidos.</li>
				<li>Estudio del Mercado: Analizar tendencias y comportamientos de los usuarios para mejorar nuestros productos y servicios.</li>
			</ul>

			<h2 className="text-xl font-semibold mt-4 mb-2">4. Compartición de Datos</h2>
			<p className="mb-2">Podemos compartir su información con terceros en las siguientes circunstancias:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Proveedores de Servicios: Terceros que nos ayudan a operar nuestro negocio, como procesadores de pagos, servicios de envío y plataformas de marketing.</li>
				<li>Obligaciones Legales: Cuando sea necesario para cumplir con leyes y regulaciones aplicables, o en respuesta a solicitudes de autoridades competentes.</li>
			</ul>

			<h2 className="text-xl font-semibold mt-4 mb-2">5. Derechos de los Usuarios</h2>
			<p className="mb-2">Los usuarios tienen los siguientes derechos en relación con su información personal:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Acceso: Solicitar acceso a la información personal que tenemos sobre usted.</li>
				<li>Rectificación: Solicitar la corrección de cualquier información incorrecta o incompleta.</li>
				<li>Cancelación: Solicitar la eliminación de su información personal.</li>
				<li>Oposición: Oponerse al procesamiento de su información personal en ciertos casos.</li>
			</ul>
			<p className="mb-2">Para ejercer estos derechos, puede contactarnos en info@tecnoficom.com.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">6. Seguridad de la Información</h2>
			<p className="mb-2">Implementamos medidas de seguridad razonables para proteger la información personal de acceso, uso o divulgación no autorizados. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de Internet.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">7. Política de Cookies</h2>
			<p className="mb-2">Utilizamos cookies para personalizar la experiencia del usuario y analizar el uso del sitio web. Las cookies son pequeños archivos de texto almacenados en su dispositivo. Puede gestionar sus preferencias de cookies a través de la configuración de su navegador. Para obtener más información sobre nuestras prácticas de cookies, consulte nuestra [Política de Cookies].</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">8. Actualizaciones a la Política de Privacidad</h2>
			<p className="mb-2">Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Notificaremos a los usuarios sobre cambios significativos mediante un aviso en nuestro sitio web o por correo electrónico. La fecha de la última actualización estará indicada al principio de esta política.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">9. Contacto</h2>
			<p className="mb-2">Si tiene alguna pregunta o inquietud sobre esta política de privacidad, puede contactarnos a través de:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Dirección: Carrera 11 No.64-54, Bogotá, Colombia</li>
				<li>Correo Electrónico: <a href="mailto:info@tecnoficom.com" className="text-blue-500">info@tecnoficom.com</a></li>
				<li>WhatsApp / PBX: +57 300 217 8613</li>
			</ul>

			<h1 className="text-2xl text-center font-bold mt-8 mb-4">Términos y Condiciones de Tienda Tecnoficom</h1>

			<h2 className="text-xl font-semibold mt-4 mb-2">1. Introducción</h2>
			<p className="mb-2">Bienvenido a Tienda Tecnoficom. Estos términos y condiciones ("Términos") rigen el acceso y uso de nuestro sitio web (www.tecnoficom.com) y los servicios ofrecidos. Al utilizar nuestro sitio web, acepta estos Términos en su totalidad. Si no está de acuerdo con estos Términos, no utilice nuestro sitio web.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">2. Aceptación de los Términos</h2>
			<p className="mb-2">Al acceder o utilizar nuestro sitio web, usted acepta cumplir y estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los Términos, no debe utilizar nuestros servicios.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">3. Uso del Sitio Web</h2>
			<h3 className="font-semibold">Reglas Generales</h3>
			<ul className="list-disc list-inside mb-2">
				<li>Debe ser mayor de 18 años para utilizar este sitio web.</li>
				<li>No debe utilizar el sitio web de ninguna manera que cause o pueda causar daño al sitio o la interrupción de la disponibilidad o accesibilidad del sitio.</li>
				<li>Está prohibido el uso del sitio web para cualquier actividad ilegal, fraudulenta o dañina.</li>
			</ul>

			<h3 className="font-semibold">Registro de Usuario</h3>
			<ul className="list-disc list-inside mb-2">
				<li>Para acceder a ciertas funciones del sitio, puede ser necesario crear una cuenta. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña y de todas las actividades que ocurran bajo su cuenta.</li>
				<li>Debe proporcionarnos información exacta y completa al registrarse en nuestro sitio web.</li>
			</ul>

			<h2 className="text-xl font-semibold mt-4 mb-2">4. Propiedad Intelectual</h2>
			<p className="mb-2">Todo el contenido del sitio web, incluidos textos, gráficos, logotipos, imágenes y software, es propiedad de Tecnoficom o de sus proveedores de contenido y está protegido por leyes de propiedad intelectual. No puede reproducir, distribuir, modificar, crear trabajos derivados, mostrar públicamente, ejecutar públicamente, republicar, descargar, almacenar o transmitir cualquier material en nuestro sitio web sin nuestro permiso previo por escrito.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">5. Limitación de Responsabilidad</h2>
			<p className="mb-2">Tecnoficom no será responsable por daños directos, indirectos, incidentales, especiales o consecuentes que resulten del uso o la incapacidad de usar nuestro sitio web, incluidos, entre otros, la pérdida de ingresos, pérdida de beneficios, pérdida de datos o interrupción del negocio, incluso si hemos sido advertidos de la posibilidad de tales daños.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">6. Enlaces a Terceros</h2>
			<p className="mb-2">Nuestro sitio web puede contener enlaces a sitios web de terceros que no son propiedad ni están controlados por Tecnoficom. No tenemos control sobre el contenido, las políticas de privacidad o las prácticas de los sitios web de terceros y no asumimos ninguna responsabilidad por ellos.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">7. Política de Devoluciones y Reembolsos</h2>
			<h3 className="font-semibold">Devoluciones</h3>
			<p className="mb-2">Los productos pueden ser devueltos dentro de los 30 días posteriores a la recepción si están en su estado original y sin usar. El cliente es responsable de los costos de envío de devolución.</p>
			<h3 className="font-semibold">Reembolsos</h3>
			<p className="mb-2">Los reembolsos serán procesados dentro de los 14 días posteriores a la recepción y verificación de los productos devueltos. Los reembolsos serán emitidos utilizando el mismo método de pago utilizado para la compra.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">8. Modificaciones a los Términos y Condiciones</h2>
			<p className="mb-2">Nos reservamos el derecho de modificar estos Términos en cualquier momento. Publicaremos cualquier cambio en esta página y, si los cambios son significativos, proporcionaremos un aviso más prominente (incluido, para ciertos servicios, notificación por correo electrónico). La fecha de la última actualización estará indicada al principio de estos Términos.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">9. Ley Aplicable y Jurisdicción</h2>
			<p className="mb-2">Estos Términos se regirán e interpretarán de acuerdo con las leyes de Colombia. Cualquier disputa relacionada con estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales de Bogotá, Colombia.</p>

			<h2 className="text-xl font-semibold mt-4 mb-2">10. Contacto</h2>
			<p className="mb-2">Si tiene alguna pregunta sobre estos Términos, puede contactarnos a través de:</p>
			<ul className="list-disc list-inside mb-2">
				<li>Dirección: Carrera 11 No.64-54, Bogotá, Colombia</li>
				<li>Correo Electrónico: <a href="mailto:info@tecnoficom.com" className="text-blue-500">info@tecnoficom.com</a></li>
				<li>WhatsApp / PBX: +57 300 217 8613</li>
			</ul>
		</div>
	);
}

export default Politics;
