import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Category, FilterProps } from './types';
import { gsap } from 'gsap';

function MobileOptions({ onClose, toggleMenu }: FilterProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const subCategoriesRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Category[]>(`${process.env.REACT_APP_API_URL}/categorias`, {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
        });
        setCategories(response.data);
      } catch (error: any) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const fetchSubcategories = async (categoryId: number) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/categorias/${categoryId}`, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
      });
      setSubCategories(response.data.subcategorias || []);
    } catch (error: any) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleClickCategory = (categoryId: number) => {
    const isSameCategory = categoryId === selectedCategoryId;

    if (selectedCategoryId !== null) {
      gsap.to(subCategoriesRef.current, { height: 0, duration: 0.3 }).then(() => {
        setSubCategories([]);
        setSelectedCategoryId(isSameCategory ? null : categoryId);
        if (!isSameCategory) {
          fetchSubcategories(categoryId);
        }
      });
    } else {
      setSelectedCategoryId(categoryId);
      fetchSubcategories(categoryId);
    }
  };

  useEffect(() => {
    if (subCategories.length > 0 && subCategoriesRef.current) {
      gsap.fromTo(subCategoriesRef.current, { height: 0 }, { height: "auto", duration: 0.3 });
    }
  }, [subCategories]);

  const handleLinkClick = () => {
    if (onClose) onClose();
    if (toggleMenu) toggleMenu();
  };

  return (
    <>
      {categories && categories.length > 0 && (
        <ul className="max-h-full">
          {categories.map((category) => (
            <li
              key={category.id}
              className="flex flex-col px-[10px] py-[10px] cursor-pointer text-tecnofi-faded-text"
              onClick={() => handleClickCategory(category.id)}
            >
              <p className={`${selectedCategoryId === category.id ? 'text-tecnofi-primary' : ''}`}>{category.nombre}</p>
              {selectedCategoryId === category.id && subCategories.length > 0 && (
                <ul ref={subCategoriesRef} className="pl-[16px] overflow-hidden">
                  {subCategories.map((subCategory) => (
                    <li key={subCategory.id} className="text-xs px-[16px] py-[6px] hover:text-tecnofi-primary">
                      <Link to={`/categories/${subCategory.id}/${encodeURIComponent(subCategory.nombre)}`} onClick={handleLinkClick}>
                        {subCategory.nombre}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default MobileOptions;
