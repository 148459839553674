import React, { useState, useEffect } from 'react';
import { Wrapper } from '../../../component';
import { Link, useParams } from 'react-router-dom';
import { ExchangeRate, Producto } from './types';
import axios from 'axios';
import { ProductProps } from '../types';

function ProductRelated({ isAuthenticated }: ProductProps) {
  const { id } = useParams();
  const [accessoriesData, setAccessoriesData] = useState<Producto[] | null>(null);
  const [relatedData, setRelatedData] = useState<Producto[] | null>(null);
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const relacionadosResponse = await axios.get<Producto[]>(`${process.env.REACT_APP_API_URL}/productos/${id}/relacionados`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          },
          params: { cop: true },
        });
        const accesoriosResponse = await axios.get<Producto[]>(`${process.env.REACT_APP_API_URL}/productos/${id}/accesorios`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          },
          params: { cop: true },
        });
        const response = await axios.get<ExchangeRate>(`${process.env.REACT_APP_API_URL}/tipocambio`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });

        setExchangeRates(response.data);
        setAccessoriesData(accesoriosResponse.data);
        setRelatedData(relacionadosResponse.data);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  function calculateProfit(producto: Producto | undefined, exchangeRates: ExchangeRate | undefined): number {
    if (!producto || !producto.precios || !producto.precios.precio_descuento || !exchangeRates || !exchangeRates.normal) return 0;

    const numberValue = typeof producto.precios.precio_descuento === 'string' ? parseFloat(producto.precios.precio_descuento) : (producto.precios.precio_descuento ?? 0);
    const profitValue = numberValue * 1.20;
    return profitValue * exchangeRates.normal;
  }



  return (
    <>
      {accessoriesData && accessoriesData.length > 0 && (
        <Wrapper isContent>
          <h2 className="font-medium text-tecnofi-text text-center text-lg mb-[10px]">Accesorios del Producto</h2>
          <section className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[12px] mb-[50px]">
            {accessoriesData.map(producto => (
              <article
                key={producto.producto_id}
                className="bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
              >
                <Link to={`/product/${producto.producto_id}/${encodeURIComponent(producto.titulo)}`}>
                  <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                    <img src={producto.img_portada} alt={producto.titulo} className="h-24" />
                  </figure>
                  <div className="text-xs px-2 py-1 break-words">
                    <h2 className="font-bold line-clamp-3">{producto.titulo}</h2>
                    <p className="text-tecnofi-faded-text">{producto.modelo}</p>
                    {isAuthenticated && (
                      <p className="font-bold text-tecnofi-primary text-sm">
                        COP: {formatCurrency(calculateProfit(producto, exchangeRates))}
                      </p>
                    )}
                    <p className="font-semibold text-tecnofi-faded-text hover:underline">{producto.marca}</p>
                  </div>
                </Link>
              </article>
            ))}
          </section>
        </Wrapper>
      )}
      {relatedData && relatedData.length > 0 && (
        <Wrapper isContent>
          <h2 className="font-medium text-tecnofi-text text-center text-lg mb-[10px]">Productos Relacionados</h2>
          <section className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[12px] mb-[50px]">
            {relatedData.map(producto => (
              <article
                key={producto.producto_id}
                className="bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
              >
                <Link to={`/product/${producto.producto_id}/${encodeURIComponent(producto.titulo)}`}>
                  <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                    <img src={producto.img_portada} alt={producto.titulo} className="h-24" />
                  </figure>
                  <div className="text-xs px-2 py-1 break-words">
                    <h2 className="font-bold line-clamp-3">{producto.titulo}</h2>
                    <p className="text-tecnofi-faded-text">{producto.modelo}</p>
                    {isAuthenticated && (
                      <p className="font-bold text-tecnofi-primary text-sm">
                        COP: {formatCurrency(calculateProfit(producto, exchangeRates))}
                      </p>
                    )}
                    <p className="font-semibold text-tecnofi-faded-text hover:underline">{producto.marca}</p>
                  </div>
                </Link>
              </article>
            ))}
          </section>
        </Wrapper>
      )}
    </>
  );
}

export default ProductRelated;
