import React, { useState } from 'react';
import { BrandProps } from './types';
import { Link } from 'react-router-dom';

function Brands({ brand }: BrandProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLogoClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="grid lg:grid-cols-10 sm:grid-cols-5 grid-cols-2 justify-center items-center gap-4 my-10">
      {brand.map((item, index) => (
        <Link key={index} to={item.link} rel="noopener noreferrer"
          className="flex justify-center transition duration-500 hover:scale-110 cursor-pointer transition-opacity opacity-50 hover:opacity-100 ease-in-out"
        >
          <img
            src={item.image}
            alt={`Logo ${index}`}
            className="w-[100px] h-auto m-4"
            onClick={() => handleLogoClick(index)}
          />
        </Link>
      ))}
    </div>
  );
}

export default Brands;
