import React from 'react';
import { type WrapperProps } from './types';

function Wrapper({ children, isContent = false }: WrapperProps) {
  const classname = isContent
    ? 'max-w-[1440px] h-full lg:px-[180px] md:px-[80px] lg:mx-auto mx-[48px]'
    : 'max-w-[1440px] h-full lg:px-[80px] lg:px-[20px] lg:mx-auto mx-[24px]';

  return (
    <div className={classname}>
      {children}
    </div>
  );
}

export default Wrapper