import React from 'react';
import { Link } from 'react-router-dom';

function LogoNavbar() {
  return (
    <>
      <Link to={"/"} className="flex-shrink-0">
        <img src="/logo.png" alt="Logo Tecnoficom" className="hidden md:flex" />
        <img src="/favicon.ico" alt="Logo Tecnoficom" className="md:hidden h-[50px] w-[46px]" />
      </Link>
    </>
  );
}

export default LogoNavbar