import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <section className="flex flex-col justify-center items-center w-full text-tecnofi-text md:ms-auto">
      <form className="w-full max-w-sm">
        <section className="mb-4">
          <label className="flex text-lg font-medium mb-2" htmlFor="email">
            Correo Electrónico
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="email"
            type="email"
            placeholder="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </section>
        <section className="mb-6">
          <label className="flex text-lg font-medium mb-2" htmlFor="password">
            Contraseña
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="password"
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </section>
        <Button
          variant="Primary"
          className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3"
          type="submit"
        >
          Acceder
        </Button>
      </form>
    </section>
  );
}

export default Login;