import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import { Link, useParams } from 'react-router-dom';
import { Producto, Brand } from './types';
import axios from 'axios';
import { ProductProps } from '../types';
import { Helmet } from 'react-helmet';

function ProductData({ isAuthenticated, addToCart, toggleLogin }: ProductProps) {
  const { id } = useParams();
  const [productData, setProductData] = useState<Producto | null>(null);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [marcaId, setMarcaId] = useState<number | null>(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productosResponse = await axios.get<Producto>(`${process.env.REACT_APP_API_URL}/productos/${id}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          },
          params: { cop: true },
        });

        const marcaResponse = await axios.get<Brand[]>(`${process.env.REACT_APP_API_URL}/marcas`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });

        const data = productosResponse.data;
        if (!data) {
          return;
        }
        const marcaEncontrada = marcaResponse.data.find(marca => marca.nombre === data.marca);
        if (marcaEncontrada) {
          setMarcaId(marcaEncontrada.id);
        }
        setProductData(data);
        setMainImage(data.img_portada);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const handleClick = (imageUrl: string) => {
    setMainImage(imageUrl);
  };

  const handleAddToCart = () => {
    if (addToCart && productData) {
      addToCart(productData, quantity);
    } else {
      console.error('addToCart function is not defined or productData is null/undefined.');
    }
  };

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    } else {
      console.error('toggleLogin function is not defined.');
    }
  }

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  function calculateProfit(value: number | string | undefined): number {
    const numberValue = typeof value === 'string' ? parseFloat(value) : (value ?? 0);
    return numberValue * 1.20;
  }

  return (
    <>
      {productData && (
        <>
          <Helmet>
            <title>Tecnoficom | {productData.titulo}</title>
            <meta name="description" content={`Detalles del producto ${productData.titulo}, de la marca ${productData.marca} en Tecnoficom. Descubre sus características, modelos disponibles y más.`} />
            <meta name="keywords" content={`${productData.titulo}, ${productData.marca}, ${productData.modelo}, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad`} />
          </Helmet>
          <section className="bg-tecnofi-section pt-[50px] pb-[30px]">
            <Wrapper isContent>
              <article key={productData.producto_id} className="flex md:flex-row flex-col gap-[20px]">
                <figure className="flex flex-col justify-center items-center rounded-[8px] gap-[12px]">
                  <img src={mainImage || ''} alt={productData.titulo} className="bg-tecnofi-background w-[300px] min-h-40 rounded-[12px]" />
                  <div className="flex gap-[12px] overflow-x-auto w-[300px]">
                    {productData.imagenes && productData.imagenes.map((imagen, index) => (
                      <img key={index} src={imagen.imagen} alt={`Referencias`} className="bg-tecnofi-background rounded-[12px] h-[75px] cursor-pointer" onClick={() => handleClick(imagen.imagen)} />
                    ))}
                  </div>
                </figure>
                <div className="text-tecnofi-text text-md">
                  <h2 className="font-semibold mt-[10px] text-3xl my-1">{productData.titulo}</h2>
                  <p>{productData.modelo}</p>
                  {isAuthenticated && productData.precios && (
                    <p className="font-bold text-tecnofi-primary text-3xl my-1">
                      COP: {formatCurrency(calculateProfit(productData.precios.precio_descuento))}
                    </p>
                  )}
                  {marcaId && <Link to={`/brand/${marcaId}/${encodeURIComponent(productData.marca)}`} className="font-medium text-tecnofi-text hover:underline">{productData.marca}</Link>}
                  <div className="flex gap-[12px]">
                    {productData.iconos && Object.values<string>(productData.iconos).map((icon, index: number) => (
                      <img key={index} src={icon} alt={`Icono ${index}`} className="h-[50px]" />
                    ))}
                  </div>
                  {productData.caracteristicas && productData.caracteristicas.length > 0 && (
                    <>
                      <h2 className="font-semibold text-tecnofi-text">Características</h2>
                      <ul>
                        {productData.caracteristicas.map((caracteristica, index) => (
                          <li key={index}>{caracteristica}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {isAuthenticated && (
                    <div className="flex gap-2 items-center mt-4">
                      <label htmlFor="quantity" className="font-medium text-tecnofi-text">Cantidad:</label>
                      <input
                        id="quantity"
                        type="number"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        className="border-[2px] border-tecnofi-primary rounded-[8px] w-[60px] py-2 px-3 bg-tecnofi-section focus:outline-none"
                      />
                    </div>
                  )}

                  {isAuthenticated ? (
                    <Button
                      variant="Primary"
                      className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg mt-4 py-2 px-3"
                      onClick={handleAddToCart}
                    >
                      Agregar al carrito
                    </Button>
                  ) : (
                    <Button
                      variant="Primary"
                      className="max-w-[220px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg mt-4 py-2 px-3"
                      onClick={handleLogin}
                    >
                      Acceder y Ver Precio
                    </Button>
                  )}
                </div>
              </article>
            </Wrapper>
          </section>
          <section className="pt-[24px] mb-[50px]">
            <Wrapper isContent>
              <article>
                <h2 className="font-medium text-center text-tecnofi-text">Descripción</h2>
                <div className="text-tecnofi-text" dangerouslySetInnerHTML={{ __html: productData.descripcion }} />
              </article>
            </Wrapper>
          </section>
        </>
      )}
    </>
  );
}

export default ProductData;
