import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CategoriesProps } from './types';

function Categories({ categories }: CategoriesProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleCategories = (index: number) => {
    setCurrentIndex(index);
    const category = categories[index];
    navigate(`/search/${encodeURIComponent(category.searchTerm)}`);
  };

  return (
    <div className="grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 justify-center items-center gap-4 my-10">
      {categories.map((item, index) => (
        <article key={index}
          className="flex flex-col justify-center items-center transition duration-500 hover:scale-110 cursor-pointer transition-opacity opacity-50 hover:opacity-100 ease-in-out"
          onClick={() => handleCategories(index)}
        >
          <p className="opacity-100 text-tecnofi-text">{item.searchTerm}</p>
          <img
            src={item.image}
            alt={`Logo ${index}`}
            className="h-[120px] m-4"
          />
        </article>
      ))}
    </div>
  );
}

export default Categories;