import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../wrapper/Wrapper';
import axios from 'axios';
import { gsap } from 'gsap';
import { type NavbarProps, type Category } from './types';

function Navbar({ className }: NavbarProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [hoveredCategoryId, setHoveredCategoryId] = useState<number | null>(null);

  const subCategoriesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await axios.get<Category[]>(`${process.env.REACT_APP_API_URL}/categorias`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });
        setCategories(responseCategories.data);
      } catch (error: any) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchData();
  }, []);

  const fetchSubcategories = async (categoryId: number) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/categorias/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });
      const category = response.data;
      if (Array.isArray(category.subcategorias)) {
        setSubCategories(category.subcategorias);
      } else {
        setSubCategories([]);
      }
      setHoveredCategoryId(categoryId);
    } catch (error: any) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    if (hoveredCategoryId !== null && subCategoriesRef.current) {
      gsap.to(subCategoriesRef.current, { opacity: 1, y: 0, duration: 0.15, ease: 'power2.out' });
    }
  }, [hoveredCategoryId]);

  const handleMouseEnter = async (categoryId: number) => {
    await fetchSubcategories(categoryId);
    if (subCategoriesRef.current) {
      gsap.to(subCategoriesRef.current, { opacity: 1, y: 0, duration: 0.15, ease: 'power2.out' });
    }
  };

  const handleMouseLeave = () => {
    if (subCategoriesRef.current) {
      gsap.to(subCategoriesRef.current, { opacity: 0, y: -20, duration: 0.15, ease: 'power2.in' }).then(() => {
        setHoveredCategoryId(null);
        setSubCategories([]);
      });
    }
  };

  return (
    <nav className={`${className} justify-between items-center border-b-[3px] border-tecnofi-primary bg-tecnofi-section w-full`} onMouseLeave={handleMouseLeave}>
      <Wrapper>
        <ul className="flex justify-center text-tecnofi-primary text-xs">
          {categories.map((category) => (
            <li
              key={category.id}
              onMouseEnter={() => handleMouseEnter(category.id)}
              className="flex items-center text-center px-[10px] py-[10px] hover:text-tecnofi-secondary hover:bg-tecnofi-select"
            >
              {category.nombre}
            </li>
          ))}
        </ul>
      </Wrapper>

      <div ref={subCategoriesRef} className="absolute border-b-[3px] border-tecnofi-primary w-screen bg-tecnofi-section z-30 opacity-0 transform -translate-y-5">

        <Wrapper>
          {hoveredCategoryId && subCategories.length > 0 && (
            <ul className="grid grid-cols-3 gap-[4px] py-[16px]">
              {subCategories.map((subCategory) => (
                <li key={subCategory.id} className="text-xs text-tecnofi-primary hover:text-tecnofi-secondary">
                  <Link to={`/categories/${subCategory.id}/${encodeURIComponent(subCategory.nombre)}`}>
                    {subCategory.nombre}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </Wrapper>
      </div>
    </nav>
  );
}

export default Navbar;
