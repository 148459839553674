import React, { useState, useEffect } from 'react';
import Button from '../../button/Button';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function SignUpPersona({ togglePolitics }: { togglePolitics: () => void }) {
  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [cedula, setCedula] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!nombre || !telefono || !email || !cedula) {
      toast.error('Por favor, completa todos los campos.');
      return;
    }
    const templateParams = {
      nombre,
      telefono,
      email,
      cedula,
    };

    emailjs.send('service_1w8fnax', 'template_ss1u14a', templateParams, 'rnEjS-QFisajygUAA')
      .then((response) => {
        toast.success('Formulario enviado exitosamente.');
        setNombre('');
        setTelefono('');
        setEmail('');
        setFile(null);
        setCedula('');
      }, (err) => {
        console.log('FAILED...', err);
        toast.error('Error al enviar el formulario. Inténtalo de nuevo.');
      });
  };

  const handdlePolitics = () => {
    togglePolitics();
  }

  return (
    <section className="flex flex-col items-center w-full text-tecnofi-text">
      <form className="w-full" onSubmit={handleSubmit}>
        <section className="grid sm:grid-cols-2 justify-center items-center gap-4 mb-4">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="nombre">
              Nombre Completo
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="nombre"
              type="text"
              placeholder="Ingrese su nombre completo"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="cedula">
              Cédula de Ciudadanía
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="cedula"
              type="text"
              placeholder="Ingrese su número de cédula"
              value={cedula}
              onChange={(e) => setCedula(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-2 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="telefono">
              Teléfono de Contacto
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="telefono"
              type="tel"
              placeholder="Ingrese su teléfono de contacto"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="email">
              Correo Electrónico
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="email"
              type="email"
              placeholder="Ingrese su correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-2 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col col-span-2">
            <label className="flex text-base font-medium mb-2" htmlFor="file">
              Adjuntar Archivo
            </label>
            <input
              className="hidden"
              id="file"
              type="file"
              onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
            />
            <label
              htmlFor="file"
              className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer w-[full] px-4 h-[52px] bg-tecnofi-section">
              Subir archivo (RUT)
            </label>
          </article>
        </section>
        <p className="text-sm text-center mb-4">
          Al registrarse, usted acepta nuestras <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Políticas de Privacidad</span>, <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Términos y Condiciones</span> y el uso del correo electrónico como medio de notificaciones de novedades.
        </p>
        <Button
          variant="Primary"
          className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3 mt-4"
          type="submit"
        >
          Regístrese
        </Button>
      </form>
    </section>
  );
}

export default SignUpPersona;
