import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Wrapper from '../../wrapper/Wrapper';
import LogoNavbar from './LogoNavbar';
import SearchBar from './Searchbar';
import MobileOptions from './MobileOptions';
import TMR from './TMR';
import { IoClose } from 'react-icons/io5';
import { NavbarProps } from './types';
import Button from '../../button/Button';

function Menu({ className, isOpen, toggleMenu }: NavbarProps) {
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      gsap.to(menuRef.current, { y: 0, duration: 0.5, ease: 'power3.out' });
    } else {
      gsap.to(menuRef.current, { y: '-100%', duration: 0.5, ease: 'power3.in' });
    }
  }, [isOpen]);

  const handleToggleMenu = () => {
    if (toggleMenu) {
      toggleMenu();
    }
  };

  return (
    <nav ref={menuRef} className={`${className} bg-tecnofi-dark pb-[32px] text-white fixed z-40 w-screen h-screen left-0 top-0`}>
      <Wrapper>
        <div className="flex flex-col h-full pt-[24px]">
          <div className="flex justify-between items-center">
            <LogoNavbar />
            <TMR />
          </div>
          <div className="flex justify-between items-center w-full bg-tecnofi-dark py-[12px]">
            <SearchBar className="block w-full" toggleMenu={toggleMenu} onClose={toggleMenu} />
            <Button variant="Dark" className="rounded-full p-[8px] absolute right-0 top-0" onClick={handleToggleMenu}>
              <IoClose size={22} fill="#fff" />
            </Button>
          </div>
          <div className="flex-1 h-0 overflow-y-auto">
            <MobileOptions toggleMenu={toggleMenu || (() => { })} />
          </div>
        </div>
      </Wrapper>
    </nav>
  );
}

export default Menu;
