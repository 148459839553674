import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Product, Wishlist } from './types';
import ProductSelect from './ProductSelect';

function WindowUpdateList({ id_lista }: { id_lista: number }) {
  const [listProducts, setListProducts] = useState(false);
  const [productos, setProductos] = useState<Product[]>([]);
  const [wishlist, setWishlist] = useState<Wishlist | null>(null);

  useEffect(() => {
    setWishlist(null);
    const fetchWishlist = async () => {
      try {
        const response = await axios.get<Wishlist>(`${process.env.REACT_APP_API_URL}/wishlists/${id_lista}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });
        setWishlist(response.data);
        setProductos(response.data.productos);
      } catch (err) {
        console.error('Error fetching wishlist:', err);
      }
    };

    fetchWishlist();
  }, [id_lista]);

  const handleAddProductClick = async (id: string) => {
    try {
      if (wishlist?.productos.some((producto) => producto.producto_id === id)) {
        console.log('Producto ya existe en la lista de deseos.');
        return;
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/wishlists/${id_lista}/productos/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });

      const newProduct = await axios.get<Product>(`${process.env.REACT_APP_API_URL}/productos/${id}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });

      setWishlist((prevWishlist) => {
        if (!prevWishlist) return prevWishlist;
        const updatedProducts = [...prevWishlist.productos, newProduct.data];
        return { ...prevWishlist, productos: updatedProducts };
      });

      setProductos((prevProductos) => {
        if (!prevProductos.some((p) => p.producto_id === id)) {
          return [...prevProductos, newProduct.data];
        }
        return prevProductos;
      });
    } catch (err) {
      console.error('Error adding product to wishlist:', err);
    }
  };

  const handleDelProductClick = async (id: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/wishlists/${id_lista}/productos/${id}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });

      setWishlist((prevWishlist) => {
        if (!prevWishlist) return prevWishlist;
        const updatedProducts = prevWishlist.productos.filter((producto) => producto.producto_id !== id);
        return { ...prevWishlist, productos: updatedProducts };
      });

      setProductos((prevProductos) => prevProductos.filter((producto) => producto.producto_id !== id));
    } catch (err) {
      console.error('Error deleting product from wishlist:', err);
    }
  };

  const handleShowProducts = () => {
    setListProducts(!listProducts)
  }

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="border-[2px] border-tecnofi-section rounded-[8px] w-full px-4 py-3 cursor-pointer text-tecnofi-text active:text-tecnofi-primary" onClick={handleShowProducts}>
        <h4 className="text-center font-medium select-none">Productos en la lista</h4>
      </div>
      {wishlist && (
        <section className={`${listProducts ? 'grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4' : 'hidden'}`}>
          {wishlist.productos.map((producto: Product) => (
            <article
              key={producto.producto_id}
              className="bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100 cursor-pointer"
              onClick={() => handleDelProductClick(producto.producto_id)}
            >
              <span className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                <img src={producto.img_portada} alt={producto.titulo} className="h-24" />
              </span>
              <div className="text-xs px-2 py-1 break-words">
                <h2 className="font-bold line-clamp-3">{producto.titulo}</h2>
                <p className="text-tecnofi-faded-text">{producto.modelo}</p>
                <p className="font-semibold text-tecnofi-primary hover:underline">{producto.marca}</p>
              </div>
            </article>
          ))}
        </section>
      )}
      <section className="flex justify-center items-center relative gap-2 mt-2">
        <h4 className="text-center text-tecnofi-text font-medium">Seleccione sus productos para la lista</h4>
        <span className="rounded-full bg-tecnofi-primary text-[#fff] text-center font-bold px-[16px] py-[7px]">
          {productos.length}
        </span>
      </section>
      <ProductSelect onProductClick={handleAddProductClick} />
    </div>
  );
}

export default WindowUpdateList;
