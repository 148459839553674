import React from 'react';
import { WindowProps } from './types';
import Wrapper from '../wrapper/Wrapper';
import Button from '../button/Button';
import { IoClose } from 'react-icons/io5';

function Window({ children, className, onClose }: WindowProps) {

  return (
    <article className={`${className} z-50 fixed top-0 left-0 w-screen h-screen py-[10vh] bg-[#33333399]`}>
      <Wrapper>
        <section className="flex bg-tecnofi-background rounded-[18px] h-full py-8 px-12 relative">
          <Button variant="Primary" className="p-[4px] rounded-[8px] absolute right-2 top-2" onClick={onClose}>
            <IoClose size={22} fill="#fff" />
          </Button>
          {children}
        </section>
      </Wrapper>
    </article>
  );
}

export default Window;
