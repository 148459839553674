import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '../../../component';
import { IoMdAddCircleOutline } from "react-icons/io";
import { WindowListProps } from './types';
import ProductSelect from './ProductSelect';

function WindowCreateList({ addNewList }: WindowListProps) {
  const [nombre, setNombre] = useState('');
  const [productos, setProductos] = useState<string[]>([]);

  const postData = async () => {
    try {
      if (nombre.length < 10) {
        console.error('El nombre debe tener al menos 10 caracteres');
        return;
      }
      const body = {
        nombre,
        productos: productos
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/wishlists`, body, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
        }
      });
      console.log('Successfully posted wishlist');
      addNewList();
      setNombre('');
      setProductos([]);
    } catch (err) {
      console.error('Error posting wishlist:', err);
    }
  };

  const handleNombreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(e.target.value);
  };

  const handleProductClick = (id: string) => {
    setProductos((prevProductos) => {
      if (!prevProductos.includes(id)) {
        return [...prevProductos, id];
      }
      return prevProductos;
    });
  };

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="md:flex md:justify-between md:items-center gap-4 mb-4">
        <h4 className="flex-shrink-0 text-center text-tecnofi-text font-medium">Nombre de la lista</h4>
        <div className="flex justify-between items-center border-[3px] border-tecnofi-primary relative bg-tecnofi-background rounded-full w-full px-[4px] py-[2px] gap-[2px]">
          <input
            type="text"
            value={nombre}
            onChange={handleNombreChange}
            className="bg-tecnofi-background text-tecnofi-text w-full rounded-full focus:outline-none font-medium font-poppins placeholder-text-tecnofi-faded-text px-[8px] py-[4px]"
            placeholder="Nombre de la lista"
          />
          <Button type="button" variant="Primary" className="md:hidden flex rounded-full px-[13px] py-[4px]" onClick={postData}>
            <IoMdAddCircleOutline size={22} fill="#fff" />
          </Button>
        </div>
        <Button type="button" variant="Primary" className="hidden md:flex rounded-full p-[8px]" onClick={postData}>
          <IoMdAddCircleOutline size={22} fill="#fff" />
        </Button>
      </div>
      <section className="flex justify-center items-center relative gap-2">
        <h4 className="text-center text-tecnofi-text font-medium">Seleccione sus productos para la lista</h4>
        <span className="rounded-full bg-tecnofi-primary text-[#fff] text-center font-bold px-[16px] py-[7px]">
          {productos.length}
        </span>
      </section>
      <ProductSelect onProductClick={handleProductClick} />
    </div>
  );
}

export default WindowCreateList;
